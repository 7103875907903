import React from 'react';

const getChildrenByDisplayName = (children, displayName) => 
    React.Children.map(children, (child) => 
        child.type.displayName === displayName ? child : null
    );

const TwoColumnContent = ({children}) => {
    const columnOne = getChildrenByDisplayName(children, 'ColumnOne');
    const columnTwo = getChildrenByDisplayName(children, 'ColumnTwo')
  return (
    <div className="grid-container">
        <div className="grid-row grid-gap">
            {columnOne}
            {columnTwo}
        </div>
    </div>
    );
};
const ColumnOne = ({children}) => <div className="desktop:grid-col-7 desktop:order-first tablet:grid-col-12 mobile:order-last">{children}</div>;
ColumnOne.displayName = "ColumnOne";
TwoColumnContent.ColumnOne = ColumnOne;

const ColumnTwo = ({children}) => <div className="text-center margin-bottom-4 desktop:grid-col-5 desktop:order-last tablet:grid-col-12 mobile:order-first">{children}</div>;
ColumnTwo.displayName = "ColumnTwo";
TwoColumnContent.ColumnTwo = ColumnTwo;


export default TwoColumnContent;