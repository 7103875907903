import React from 'react';
import TechnicalAssistanceLanding from 'templates/technical-assistance-layout';
import {useStrapiTechnicalAssistancePageData} from "hooks";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const TechnicalAssistance = ({location}) => {
     let thisLocationPathname = ''

     if (isBrowser) {
         thisLocationPathname = window.location.pathname
     }
     const { strapiTechnicalAssistancePage } = useStrapiTechnicalAssistancePageData();

    return (
        <TechnicalAssistanceLanding
            location={location}
            pathname={thisLocationPathname}
            strapiTechnicalAssistancePage={strapiTechnicalAssistancePage}
        />
    )
};

export default TechnicalAssistance;
