import React, {useState, useEffect} from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import { Overlay } from "components/global";
import { Hero, Breadcrumb, Card, Modal, Button, ExternalIcon, VideoComponent } from "components/ui";
import { Link } from "gatsby";
import TwoColumnContent from "templates/two-column-content-layout.js";
import ResourcesAndConnection from "assets/images/resources_and_connection.svg";
import TailoredConsultation from "assets/images/tailored_consultation.svg";
import SpecializedTechnicalAssistance from "assets/images/specialized_technical_assistance.svg";
import TrainingOngoingConsultation from "assets/images/training_and_ongoing_consultation.svg";
import styled from "styled-components";
import { getSrc } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStrapiVideosWebinarsData  } from "hooks";


const technicalAssistanceResourcesData=[
    {
        title: "In-Depth Technical Assistance",
        description: "NCSACW's In-Depth Technical Assistance (IDTA) program enables states, Tribes, and their community partner agencies to better improve the...",
        slug: "idta"
    },
    {
        title: "Regional Partnership Grant Program",
        description: "The Regional Partnership Grant (RPG) Program is administered by the Administration for Children, Youth and Families (ACYF), Children’s...",
        slug: "rpg"
    }
];

const technicalAssistanceCards=[
    {
        title: "Resources and Connection",
        description: "Curated information, materials, and collaborative tools to support knowledge development and linkages to effective policy and practice"
    },
    {
        title: "Tailored Consultation",
        description: "Customized, solution focused resources to increase knowledge and advance collaboration, for programs, policies, and practices"
    },
    {
        title: "Training and Ongoing Consultation",
        description: "Onsite or virtual TTA focused on a range of topics, including policy and practice strategies to support collaboratives and the workforce"
    },
    {
        title: "Specialized Technical Assistance",
        description: "Capacity building and implementation support to strengthen partnerships, sustain innovations, measure performance, improve outcomes"
    }
];

const TechnicalAssistanceLanding = ({strapiTechnicalAssistancePage,pathname, location}) => {
    const Icon= styled.div`
        position: absolute;
        display: block;
        top: -50px;
        left: 50%;
        margin-left: -55px;
        height: 115px;
        width: 115px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        border-radius: 50%;
        background-size: 50px 50px;
        border: 10px solid #fff;
    `;
      const CardImage= styled.div`
        height: 100px;
        width: 100px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        border-radius: 50%;
        background-size: 50px 50px;
        margin: 0 auto;
    `;
    const [technicalAssistanceTitle, setTechnicalAssistanceTitle] = useState("");
    const [technicalAssistance, setTechnicalAssistance] = useState({});
    const { allStrapiVideo } = useStrapiVideosWebinarsData();
    const [selectedVideoTitle, setSelectedVideoTitle] = useState("");
    const [selectedVideoId, setSelectedVideoId] = useState("");
    const [videoModalDisplay, setVideoModalDisplay] = useState(false);
    const [modalDisplay, setModalDisplay] = useState(false);
    useEffect(()=>{
        let technicalAssistanceData = technicalAssistanceCards.filter(ta=>{
            return ta.title === technicalAssistanceTitle
        })
        setTechnicalAssistance(technicalAssistanceData[0])
    },[technicalAssistanceTitle])

    const openModal=e=>{
        setTechnicalAssistanceTitle(e.target.parentElement.querySelector('.technical-assistance-card-heading').textContent);
        e.target.parentElement.classList.add("usa-focus");
        setModalDisplay(true);
    }
    const closeModal=()=>{
        (document.querySelector(".usa-focus")&& document.querySelector(".usa-focus").classList.remove("usa-focus"));
        setModalDisplay(false);
    }
    const addHoverAnimation=e=>{
        e.target.closest(".usa-card__container").classList.add("hover");
    }
    const removeHoverAnimation=e=>{
        e.target.closest(".usa-card__container").classList.remove("hover");
    }
    const seo = {
        metaTitle: strapiTechnicalAssistancePage?.title,
        metaDescription: strapiTechnicalAssistancePage?.description,
        metaKeywords: strapiTechnicalAssistancePage?.keywords
      }
    const technicalAssistanceResources = (strapiTechnicalAssistancePage?.resources || strapiTechnicalAssistancePage?.videos_and_webinars) ?
      [...technicalAssistanceResourcesData,...strapiTechnicalAssistancePage?.resources,...strapiTechnicalAssistancePage?.videos_and_webinars] :
      [...technicalAssistanceResourcesData]
    const selectedVideo=e=>{
        e.preventDefault();
        setVideoModalDisplay(!videoModalDisplay);
        let selectedVideo = allStrapiVideo?.nodes.filter(resource=>{
            return resource?.title === e.target.title;
        })
        setSelectedVideoTitle(selectedVideo[0].title);
        selectedVideo[0]?.video_url.includes("=") ? setSelectedVideoId(selectedVideo[0]?.video_url.substring(selectedVideo[0]?.video_url?.indexOf('=')+1)) : setSelectedVideoId(selectedVideo[0]?.video_url.substring(selectedVideo[0]?.video_url.indexOf('.')+4));
    }
    const updateModalDisplay=e=>{
      e.preventDefault();
      setVideoModalDisplay(!videoModalDisplay);
    }
  return (
    <Layout location={location}>
        <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
        <div>
            <Hero
            img={getSrc(strapiTechnicalAssistancePage?.hero_image?.background_image?.localFile)}
            color="#f5f5f5"
            isFixedHeight={false}
            >
                <Breadcrumb pathname={pathname} />
                <h1 name="main-content" id="main-content" className="usa-hero__heading">{strapiTechnicalAssistancePage?.hero_image?.headline}</h1>
                {strapiTechnicalAssistancePage?.hero_image?.description &&
                <div
                  dangerouslySetInnerHTML={{
                  __html: strapiTechnicalAssistancePage?.hero_image?.description?.data?.childMarkdownRemark?.html,
                  }}
                />
              }
            </Hero>
        </div>
        <div className="padding-y-5">
            <TwoColumnContent>
                <TwoColumnContent.ColumnOne>
                {strapiTechnicalAssistancePage?.text &&
                    <div
                        dangerouslySetInnerHTML={{
                        __html: strapiTechnicalAssistancePage?.text?.data?.childMarkdownRemark?.html,
                        }}
                        className="margin-top-0 unordered-list-style"
                    />
                }
                </TwoColumnContent.ColumnOne>
                <TwoColumnContent.ColumnTwo>
                    <div className="padding-top-3">
                        <GatsbyImage
                        className="ta-idta-image"
                        image={getImage(strapiTechnicalAssistancePage?.image?.localFile)}
                        alt={strapiTechnicalAssistancePage?.image?.alternativeText ?? ""}
                        />
                    </div>
                </TwoColumnContent.ColumnTwo>
            </TwoColumnContent>
            <div className="padding-y-5">
            <div className="grid-container">
                <div className="usa-card-group">
                    {technicalAssistanceCards.map((resource, idx)=>{
                    return (
                        <Card key={`technical-assistance-resource-${idx + 1}`}
                        grid="desktop:grid-col-3 tablet:grid-col-6 mobile:grid-col-12 tablet:margin-top-4 mobile:margin-top-3"
                        size="minh-card-lg"
                        borderRadius="0.5rem"
                        background={(idx+1)%2===0 ? "primary-green-bg": "secondary-dark-blue-bg"}
                        color="text-white"
                        onClick={openModal}
                        tabIndex={0}
                        roundImage={true}
                        addHoverAnimation={addHoverAnimation}
                        removeHoverAnimation={removeHoverAnimation}>
                            <Card.Header>
                            {(() => {
                            switch(idx) {
                                case 0:
                                    return <Icon className="secondary-dark-blue-bg" style={{backgroundImage: `url(${ResourcesAndConnection})`}}/>
                                case 1:
                                    return <Icon className="primary-green-bg" style={{backgroundImage: `url(${TailoredConsultation})`}}/>
                                case 2:
                                    return <Icon className="secondary-dark-blue-bg" style={{backgroundImage: `url(${TrainingOngoingConsultation})`}}/>
                                case 3:
                                    return <Icon className="primary-green-bg" style={{backgroundImage: `url(${SpecializedTechnicalAssistance})`}}/>
                                default:
                                    return <Icon className="secondary-dark-blue-bg" alt=""/>
                                    }
                            })()}
                                <h4 className="technical-assistance-card-heading margin-top-10 text-center text-white">{resource.title}</h4>
                            </Card.Header>
                        </Card>
                    )})}
                </div>
                <Overlay isVisible={modalDisplay || videoModalDisplay} />
                <Modal
                    modalId="technical-assistance"
                    modalType="usa-modal--lg"
                    modalDisplay={modalDisplay}>
                    <Modal.ModalHeader headerId="technical-assistance-heading">
                        {(() => {
                            switch(technicalAssistanceTitle) {
                                case "Resources and Connection":
                                    return <CardImage className="secondary-dark-blue-bg margin-top-2" style={{backgroundImage: `url(${ResourcesAndConnection})`}}/>
                                case "Tailored Consultation":
                                    return <CardImage className="primary-green-bg margin-top-2" style={{backgroundImage: `url(${TailoredConsultation})`}}/>
                                case "Training and Ongoing Consultation":
                                    return <CardImage className="secondary-dark-blue-bg margin-top-2" style={{backgroundImage: `url(${TrainingOngoingConsultation})`}}/>
                                case "Specialized Technical Assistance":
                                    return <CardImage className="primary-green-bg margin-top-2" style={{backgroundImage: `url(${SpecializedTechnicalAssistance})`}}/>
                                default:
                                    return <CardImage className="secondary-dark-blue-bg margin-top-2" alt="Missing icon"/>
                                }
                            })()}
                        <h2>{technicalAssistanceTitle}</h2>
                    </Modal.ModalHeader>
                    <Modal.ModalButton closeModal={closeModal}/>
                    <Modal.ModalBody>
                        {technicalAssistance &&
                            <div className="usa-modal__main">
                                <p>{technicalAssistance.description}</p>
                            </div>
                        }
                    </Modal.ModalBody>
                    <Modal.ModalFooter modalFooterClass="text-center">
                        <Button color="primary-button" size="width-card-lg margin-bottom-3" onClick={closeModal}>
                            Close
                        </Button>
                    </Modal.ModalFooter>
                </Modal>
            </div>
        </div>
        </div>
        <div className={`padding-y-5 light-gray-bg`}>
            <div className="grid-container">
                <h2>Specialized Technical Assistance Projects</h2>
                <div className="usa-card-group">
                    {technicalAssistanceResources.map((resource, idx)=>{
                    return (
                        <Card key={`technical-assistance-project-${idx + 1}`}
                        grid="desktop:grid-col-6"
                        background="bg-white">
                            <Card.Header>
                                {resource?.title && <h2 className="usa-card__heading">{resource?.title && resource?.title.substring(0,38)}{(resource?.title && resource?.title.length > 38) && <span>...</span>}</h2> }
                            </Card.Header>
                            <Card.Body>
                              <>
                              {(resource?.video_url && resource?.body) ? 
                                    <>
                                        {resource?.date && <p>Date: {resource?.date.substring(0,70)}</p> }
                                        <span
                                                dangerouslySetInnerHTML={{
                                                __html: resource?.body?.data?.childMarkdownRemark?.excerpt,
                                                }}
                                                className="card-text" 
                                            />... 
                                        <span>(<Link to={`/training/videos-and-webinars/${resource?.slug && resource?.slug}`}>Read More</Link>)</span>
                                    </>
                                :
                                resource?.body ?
                                <>
                                    <span
                                        dangerouslySetInnerHTML={{
                                        __html: resource?.body?.data?.childMarkdownRemark?.excerpt,
                                        }}
                                        className="margin-top-0 unordered-list-style card-text"
                                    />
                                    <span>(<Link to={`${resource?.slug}`}>Read More</Link>)</span>
                                </>
                                :
                                    <p>{resource?.description && resource?.description.substring(0,136)}{(resource?.description  && resource?.description.length > 136) && <><span>...</span>{resource?.pdf && <Link to={`${resource?.slug}`}>(Read More)</Link>}</>}</p>
                                }
                                </>
                            </Card.Body>
                            <Card.Footer
                            cardFooter="text-center">
                                {resource?.pdf ?
                                <>
                                    <Button color="primary-button" size="padding-x-5" link={`/files/${resource?.pdf && resource?.pdf?.name}`}>
                                        View Document { ` (PDF ${resource?.pdf?.localFile?.prettySize.toUpperCase()})`}
                                    </Button>
                                    {resource?.external_resource && <ExternalIcon />}
                                </>
                                :
                                resource?.video_url ?
                                <>
                                   <Button color="primary-button" type="button" size="padding-x-5" labelText={`Open ${resource?.title} Video in page`} onClick={selectedVideo} title={resource?.title}>
                                       Watch Video
                                    </Button>
                                    {resource?.external_resource && <ExternalIcon /> }
                                </>
                                :
                                <Button color="primary-button" size="padding-x-5" link={resource?.slug}>Learn More</Button>
                                }
                            </Card.Footer>
                        </Card>
                    )
                    })}
                </div>
            </div>
        </div>
        <Modal
          modalId={`technical-assistance-page-video-webinar-modal`}
          modalType="usa-modal--lg radius-lg"
          modalDisplay={videoModalDisplay}
        >
          <Modal.ModalButton closeModal={updateModalDisplay}/> 
            <Modal.ModalBody modalBodyClass="text-center">
              <div className="padding-top-6">
                <VideoComponent
                  videoSrcId={selectedVideoId}
                  videoTitle={selectedVideoTitle}
                />
              </div>
            </Modal.ModalBody>
            <Modal.ModalFooter modalFooterClass="text-center">
              <Button color="primary-button" size="width-card-lg margin-bottom-3" closeModal={true} onClick={updateModalDisplay}>
                Close
              </Button>
            </Modal.ModalFooter> 
        </Modal>
    </Layout>
  );
};

export default TechnicalAssistanceLanding;
